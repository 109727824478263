import * as Types from '@sal-solution/types/types/fish-shop/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindShopQueryVariables = Types.Exact<{
  shopId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  slug?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FindShopQuery = { __typename?: 'Query', shop?: { __typename?: 'Shop', status?: string | null, _id: string, name: string, phone?: string | null, logo?: string | null } | null };


export const FindShopDocument = gql`
    query findShop($shopId: ID, $slug: String) {
  shop(_id: $shopId, slug: $slug) {
    status
    _id
    name
    phone
    logo
  }
}
    `;

/**
 * __useFindShopQuery__
 *
 * To run a query within a React component, call `useFindShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFindShopQuery(baseOptions?: Apollo.QueryHookOptions<FindShopQuery, FindShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindShopQuery, FindShopQueryVariables>(FindShopDocument, options);
      }
export function useFindShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindShopQuery, FindShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindShopQuery, FindShopQueryVariables>(FindShopDocument, options);
        }
export function useFindShopSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindShopQuery, FindShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindShopQuery, FindShopQueryVariables>(FindShopDocument, options);
        }
export type FindShopQueryHookResult = ReturnType<typeof useFindShopQuery>;
export type FindShopLazyQueryHookResult = ReturnType<typeof useFindShopLazyQuery>;
export type FindShopSuspenseQueryHookResult = ReturnType<typeof useFindShopSuspenseQuery>;
export type FindShopQueryResult = Apollo.QueryResult<FindShopQuery, FindShopQueryVariables>;