'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Section, StatusBar, StatusBarProps, Text } from '@sal-solution/ui-web';
import tw from 'twin.macro';
import { useFindShopQuery } from '@/modules/graphql/findShop.generated';
import { useParams } from 'next/navigation';
import { ComponentProps } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useShopSlug } from '@/hooks/useShopSlug';

type statusTypes = NonNullable<ComponentProps<typeof StatusBar>['status']>;

const statusTexts: Record<statusTypes, string> = {
  success: 'Wij zijn nu op zee, U kunt bestellen.',
  warning: 'Bestellen is straks niet meer mogelijk. Bestel nu!',
  error: 'Wij zijn niet op zee, U kunt niet bestellen.',
  unknown: '',
};

const Nav = tw.nav`grid lg:grid-cols-12`;

export const Navigation = () => {
  const slug = useShopSlug();
  const shopStatus = useFindShopQuery({
    variables: {
      slug: slug as string,
    },
    skip: !slug,
  });

  const shop = shopStatus.data?.shop;

  const status = (shop?.status as StatusBarProps['status']) || 'unknown';
  return (
    <>
      <Nav>
        <Section tw="py-0 lg:py-4">
          <div className="navbar bg-base-100">
            <div className="navbar-start">
              <div className="dropdown">
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost btn-circle"
                >
                  <NavIcon />
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[10] mt-3 w-52 p-2 shadow"
                >
                  <li>
                    <Link href={`/winkelmand`}>Winkelmand</Link>
                  </li>
                  <li>
                    <Link href={`/`}>Producten</Link>
                  </li>
                  {/* <li>
                  <a>Contact</a>
                </li> */}
                </ul>
              </div>
            </div>
            <div className="navbar-center">
              <Image
                src={shop?.logo || '/logos/icon.png'}
                tw="mr-2 rounded-full"
                alt="logo"
                width={35}
                height={35}
              />
              {shop && (
                <Text as={Link} href={`/`} variant="subTitle">
                  {shop?.name}
                </Text>
              )}
            </div>
            <div className="navbar-end">
              {shop && (
                <StatusBar
                  tw="hidden lg:(px-4 block rounded-md w-fit ml-auto)"
                  status={status}
                >
                  {statusTexts[status]}
                </StatusBar>
              )}
            </div>
          </div>
        </Section>
      </Nav>
      <Section tw="py-0 sticky top-0 z-10 lg:hidden ">
        {shop && (
          <StatusBar tw="w-full px-4 rounded-none" status={status}>
            {statusTexts[status]}
          </StatusBar>
        )}
      </Section>
    </>
  );
};

const NavIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16M4 18h7"
      />
    </svg>
  );
};
