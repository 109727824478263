import React, { FC, PropsWithChildren } from 'react';

type CartItemProduct = {
  _id: string;
  qty: string;
};

type CartItem = {
  shopId: string;
  products: CartItemProduct[];
  createdAt: string;
  updatedAt: string;
};

type CartContextProps = {
  carts: CartItem[];
  addItem: (productId: string, qty: string, shopId: string) => void;
  removeItem: (productId: string, shopId: string) => void;
  clearCart: (shopId: string) => void;
  clearCarts: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

const CartContext = React.createContext<CartContextProps>({
  carts: [],
  addItem: emptyFunc,
  removeItem: emptyFunc,
  clearCart: emptyFunc,
  clearCarts: emptyFunc,
});

const storeCart = (carts: CartItem[]) => {
  const cartsString = JSON.stringify(carts);
  localStorage.setItem('carts', cartsString);
};

const getCart = () => {
  const cartsString = localStorage.getItem('carts');
  if (!cartsString) {
    return [];
  }
  return JSON.parse(cartsString);
};

export const CartClient: FC<PropsWithChildren> = ({ children }) => {
  const [carts, setCarts] = React.useState<CartItem[]>([]);
  const clearCart = (shopId: string) => {
    const newCarts = carts.filter((cart) => cart.shopId !== shopId);
    setCarts(newCarts);
    storeCart(newCarts);
  };

  const clearCarts = () => {
    setCarts([]);
    storeCart([]);
  };

  const addItem = (productId: string, qty: string, shopId: string) => {
    console.log('add item', productId, qty, shopId);
    const shopCart = carts.findIndex((cart) => cart.shopId === shopId);
    if (shopCart === -1) {
      const newCart = {
        shopId,
        products: [{ _id: productId, qty }],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      setCarts([...carts, newCart]);
      storeCart([...carts, newCart]);
      return;
    } else {
      const newCarts = carts.map((cart) => {
        if (cart.shopId === shopId) {
          const product = cart.products.find(
            (p) => String(p._id) === productId
          );
          if (product) {
            product.qty = qty;
          } else {
            cart.products.push({ _id: productId, qty });
          }
        }
        return cart;
      });
      setCarts(newCarts);
      storeCart(newCarts);
    }
  };

  const removeItem = (productId: string, shopId: string) => {
    const newCarts = carts.filter((cart) => {
      if (cart.shopId === shopId) {
        cart.products = cart.products.filter((product) => {
          return String(product._id) !== productId;
        });
      }
      return cart.products.length > 0;
    });
    setCarts(newCarts);
    storeCart(newCarts);
  };

  React.useEffect(() => {
    const carts = getCart();
    setCarts(carts);
  }, []);

  return (
    <CartContext.Provider
      value={{
        carts,
        addItem,
        removeItem,
        clearCart,
        clearCarts,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }

  const findCart = (shopId: string) => {
    return context.carts.find((cart) => cart.shopId === shopId);
  };

  return { findCart, ...context };
};
