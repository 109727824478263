'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useFindShopQuery } from '@/modules/graphql/findShop.generated';
import { StatusBar } from '@sal-solution/ui-web';
import { useParams } from 'next/navigation';
import { ComponentProps, FC } from 'react';

type statusTypes = NonNullable<ComponentProps<typeof StatusBar>['status']>;

const statusTexts: Record<statusTypes, string> = {
  success: 'Wij zijn nu op zee, U kunt bestellen.',
  warning: 'Bestellen is straks niet meer mogelijk. Bestel nu!',
  error: 'Wij zijn niet op zee, U kunt niet bestellen.',
  unknown: '',
};

export const ShopStatusBar: FC = () => {
  const params = useParams();

  const foundShop = useFindShopQuery({
    variables: {
      slug: params.slug as string,
    },
    skip: !params.slug,
  });

  const shop = foundShop.data?.shop;
  return (
    <div tw="fixed top-4 w-full max-w-screen-md z-20 px-4 flex flex-col space-y-4">
      <StatusBar
        tw="w-full"
        status={(shop?.status as statusTypes) || 'success'}
      >
        {statusTexts[(shop?.status as statusTypes) || 'success']}
      </StatusBar>
      <StatusBar status="warning" tw="w-full hidden lg:flex">
        Onze website is momenteel voor mobile bedoelt. Op desktop kunt u een
        minder fijne ervaring beleven en raden we daarom aan om over te
        schakelen naar mobiel
      </StatusBar>
    </div>
  );
};
